<template>
  <div class="pt-5">
    <modal-loading :is-loading="loading" message="ログイン中..." />
    <v-row justify="center">
      <form class="col-5" @keyup.enter="handleLogin">
        <v-alert v-if="passwordResetSuccessMessage" text class="mb-5" color="success">
          {{ passwordResetSuccessMessage }}
        </v-alert>
        <div>
          <v-text-field v-model="formData.username" type="text" label="メールアドレス" :error-messages="errorMessage.email"
            outlined />
        </div>
        <div>
          <v-text-field v-model="formData.password" type="password" label="パスワード" :error-messages="errorMessage.password"
            outlined />
        </div>
        <div v-if="errorMessage.auth">
          <v-alert text color="error">
            {{ errorMessage.auth }}
          </v-alert>
        </div>
        <div class="px-3">
          <v-row justify="end">
            <v-btn color="primary" large @click="handleLogin"> ログイン </v-btn>
          </v-row>
        </div>
      </form>
    </v-row>
  </div>
</template>

<script>
import ModalLoading from "../components/ModalLoading.vue";
import { updateData } from "../axios";

export default {
  name: "Login",

  components: {
    ModalLoading,
  },

  data() {
    return {
      loading: false,
      formData: {
        username: "",
        password: "",
      },
      errorMessage: {
        email: "",
        password: "",
        auth: "",
      },
      passwordResetSuccessMessage: "",
    };
  },

  created() {
    if (
      this.$route.query.from === "password_reset" &&
      this.$route.query.email
    ) {
      this.passwordResetSuccessMessage = "パスワードを更新しました。";
      this.formData.username = this.$route.query.email;
    }
  },

  methods: {
    handleLogin() {
      this.loading = true;
      Object.keys(this.errorMessage).forEach((key) => {
        this.errorMessage[key] = "";
      });
      if (this.formData.username === "") {
        this.loading = false;
        this.errorMessage.email = "メールアドレスを入力してください。";
        return;
      }
      if (this.formData.password === "") {
        this.loading = false;
        this.errorMessage.password = "パスワードを入力してください。";
        return;
      }

      this.$store.dispatch("auth/login", this.formData).then((res) => {
        if (res.status === 200) {
          this.loading = false;
          Promise.all([
            this.$store.dispatch("auth/setMe"),
            this.$store.dispatch("auth/setClient"),
          ]).then(() => {
            updateData("me/lastLogin", "");
            this.$router.push("/photos");
          });
        } else {
          this.loading = false;
          this.errorMessage.auth =
            "メールアドレスかパスワードが間違っています。";
        }
      });
    },
  },
};
</script>
